import { addWaiter, isLocal, isSSR } from '@/utils/commonUtils'
import { CategoryPreferences, loadNonSegmentScripts, managerContent, normalizedDestinations, parseHTML, Preferences } from './utils'
import { stripHtml } from '@/utils/stringUtils';
import Cookies from 'js-cookie';

const global = (window as any);

export const openConsentManager = () => {
  try {
    global.consentManager.openConsentManager()
  } catch (e) {
    window.open('/privacy-policy/cookie-policy/')
  }
}

export const GOOGLE_GRANTED_PARAMS = {
  'ad_user_data': 'granted',
  'ad_personalization': 'granted',
}
export const GOOGLE_DENIED_PARAMS = {
  'ad_user_data': 'denied',
  'ad_personalization': 'denied',
}

export const startConsentManager = (writeKey: string, content: managerContent, categories: normalizedDestinations, isUSA: boolean, cookieName: string, callback: Function) => {
  if (!isSSR()) {
    const { 
      bannerContent,
      bannerSubContent,
      cancelDialogContent,
      cancelDialogTitle,
      functionalCategoryDescription,
      marketingAndAnalyticsCategoryDescription,
      targetingAndAdvertisingCategoryDescription,
      additionalIntegrationsCategoryDescription,
      preferencesDialogContent,
      preferencesDialogTitle,
      enableConsentManager,
      okCta,
    } = content;

    if (!enableConsentManager) {
      global.analytics.load(writeKey, {integrations: {All: true}})
      loadNonSegmentScripts()
      return
    }

    let interactionCounter = 0;
    const interactionCookie = 'tracking-preferences-us-interacted'
    const containerSelector = '#segment-consent-container';
    const managerContainer = document.querySelector(containerSelector);

    const getInitialPreferences = (isUSA: boolean): CategoryPreferences => {
      const iAllowed = isUSA ? true : null
      return {
        ...(categories.functionalCategories.length && {
          'Functional': iAllowed
        }),
        'Marketing and Analytics': iAllowed,
        'Targeting and Advertising': iAllowed,
        'Additional Integrations': iAllowed,
      }
    }

    global.consentManagerConfig = function(exports) {
      const createElement = exports.React.createElement
      const customPreferences = () => exports.preferences.loadPreferences().customPreferences

      const initialPreferences = getInitialPreferences(isUSA)
      const preferences: Preferences = {
        customPreferences: initialPreferences,
        destinationPreferences: categories.categoryPreferences,
        cookieName
      }

      managerContainer.addEventListener('click', event => {
        const el = event.target as HTMLButtonElement
        if (isUSA) {
          if (el.title === 'Close' || el.dataset.type ==='Manage') {
            setUSInteracted(true)
          }
        }
      })
  
      const setUSInteracted = (isManual = false) => {
        if (interactionCounter || isManual) {
          Cookies.set(interactionCookie, true, { expires: 365, domain: !isLocal() ? `.expeditions.com` : null })
        }
        if (isManual) {
          exports.preferences.savePreferences(preferences)
        } else if (!Cookies.get(interactionCookie) && Cookies.get(cookieName)) {
          Cookies.remove(cookieName, !isLocal() ? { domain: `.expeditions.com` } : null)
        }
        interactionCounter++
      }

      if (!isUSA) {
        managerContainer.classList.add('not-usa')
      }
      
      const handleNonSegmentScripts = () => {
        let isAdditional: boolean = null
        const savedCookiesSTG = cookieName.endsWith('stg') ? Cookies.getJSON('tracking-preferences-stg') : null
        const customPrefs = customPreferences() || savedCookiesSTG
        if (cookieName.endsWith('stg')) {
          isAdditional = savedCookiesSTG && savedCookiesSTG.custom['Additional Integrations']
        } else {
          isAdditional = customPreferences() ? customPreferences()['Additional Integrations'] : null
        }
        const usaInitial = isUSA && !customPrefs
        if (isAdditional || usaInitial) {
          loadNonSegmentScripts()
        }
      }
      handleNonSegmentScripts()

      exports.preferences.onPreferencesSaved(() => {
        const isMarketing = customPreferences() ? customPreferences()['Marketing and Analytics'] : null
        if (global.gtag) {
          if (isMarketing) {
            global.gtag('consent', 'update', GOOGLE_GRANTED_PARAMS)
          } else {
            global.gtag('consent', 'update', GOOGLE_DENIED_PARAMS)
          }
        }
        if (isUSA) {
          setUSInteracted()
        }
        handleNonSegmentScripts()
        callback()
      })

      const customCategories = {
        ...(categories.functionalCategories.length && {
          'Functional': {
            integrations: categories.functionalCategories,
            purpose: stripHtml(functionalCategoryDescription),
          }
        }),
        'Marketing and Analytics': {
          integrations: categories.marketingCategories,
          purpose: parseHTML(marketingAndAnalyticsCategoryDescription, 'div', 'p', createElement),
        },
        'Targeting and Advertising': {
          integrations: categories.advertisingCategories,
          purpose: parseHTML(targetingAndAdvertisingCategoryDescription, 'div', 'p', createElement),
        },
        'Additional Integrations': {
          purpose: stripHtml(additionalIntegrationsCategoryDescription),
          integrations: ['Navistone', 'Movable Ink']
        },
      }

      const bannerContentReact = parseHTML(bannerContent, 'span', 'span', createElement)
      const preferencesDialogContentReact = parseHTML(preferencesDialogContent, 'div', 'p', createElement)
      const bannerActionsBlock = ({ acceptAll, changePreferences }) => (
        createElement('div', { class: 'controlsWrap' },
          createElement('button', { type: 'button', class: 'acceptBtn button button--primary',
            onClick: isUSA ? () => { setUSInteracted(true), managerContainer.remove() } : acceptAll
          }, okCta),
          createElement('button', { type: 'button', class: 'changeBtn', onClick: changePreferences, 'data-type': 'Manage' }, stripHtml(bannerSubContent)),
        )
      )

      addWaiter(
        () => document.querySelector('#segment-consent-container button:empty') || Cookies.get(cookieName),
        () => {
          const emptyBtn = document.querySelector('#segment-consent-container button:empty')
          const closeBtn = document.querySelector('#segment-consent-container button[title="Close"]')
          if (emptyBtn) emptyBtn.remove()
          if (closeBtn) closeBtn.removeAttribute('aria-label')
        },
        3000,
      );

      return {
        cookieName: cookieName,
        container: containerSelector,
        writeKey: writeKey,
        shouldRequireConsent: () => true,
        bannerContent: bannerContentReact,
        bannerSubContent: '',
        preferencesDialogTitle: stripHtml(preferencesDialogTitle),
        preferencesDialogContent: preferencesDialogContentReact,
        cancelDialogTitle: stripHtml(cancelDialogTitle),
        cancelDialogContent: stripHtml(cancelDialogContent.replace(/&#160;/g, ' ')),
        customCategories: customCategories,
        bannerActionsBlock: bannerActionsBlock,
        initialPreferences: initialPreferences,
        defaultDestinationBehavior: 'imply',
        closeBehavior: isUSA ? 'dismiss' : 'deny',
      }
    }

    const script = document.createElement('script');
    script.src = 'https://unpkg.com/@segment/consent-manager@5.3.0/standalone/consent-manager.js';
    document.head.appendChild(script);
  }
}