import { RootGetters } from '@/store/getters';
import Vue from 'vue';
import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';
import cloneDeep from 'lodash/cloneDeep';

import { RootState } from '@/store/types';

import { getStore } from '@/store/index';
import { CHANGE_WINDOW_SIZE } from '../mutations';
import { anchorBarOffset, INIT_WINDOW_WATCHERS, windowOffset } from './windowModule';
import { isSSR } from '@/utils/commonUtils';
import { INIT_GROUP_URI } from './groupUriModule';
import { modulePageOffset } from '@/utils/domUtils';

export interface PageModule {
  Id: string;
  ContentLinkId: number;
  UUID?: string;
  Type: string;
  Data: any;
  GroupUri?: string;
  IsHiddenGroupedUriInNav: boolean;
  VuexModulePath?: string;
  // IsPreLoaded?: boolean; // Prepered to be mounted to DOM (convert and store registration)
  // IsVisible?: boolean; // IO is in screen now
  IsMounted?: boolean; // Mounted to DOM (after hook mounted)
}

export interface PageSlot {
  Name: string;
  Modules: PageModule[];
}

export interface PageData {
  Layout: string;
  LayoutParams: any;
  Slots: PageSlot[];
}

export interface ModulesStatus {
  PageData: PageData;
  FirstVisibleModule: PageModule;
  IsAfterSSR?: boolean;
  RegisteredModules: string[];
  IsHydrate: boolean;
  // IsAllModulesLoaded: boolean;
}

interface ModuleLoadingParams {
  Module: PageModule;
  VuexModulePath?: string;
  Data?: any;
}

export interface ModuleVisiblityParams {
  Module: PageModule;
  IsVisible: boolean;
}

export const START_LOADING = 'START_LOADING';
export const CLEAR_MODULE_GROUP_URI = 'CLEAR_MODULE_GROUP_URI';
const actions: ActionTree<ModulesStatus, RootState> = {
  async [START_LOADING]({ dispatch, commit, getters, state }) {
    // store.commit(SET_PAGE_DATA, pageData);
    const isAfterSSR = state.IsAfterSSR;

    const gettersT = getters as ModulesLoadGetters;

    // console.log('isSSR:', isSSR());
    if (!isSSR()) {
      commit(CHANGE_WINDOW_SIZE, window.innerWidth);
    }

    const loadModule = (m) => {
      return new Promise(async (resolve, reject) => {
        const result: ModuleLoadingParams = {
          Module: m,
        };

        const { Type, Data } = m;

        if (!isAfterSSR) {
          await import(`@/modules/${Type}/${Type}.types`)
            .then(({ convert }) => {
              // console.log('convert', Type);
              result.Data = convert(cloneDeep(Data));
            })
            .catch((ex) => {
              if (
                !ex.message.includes('is not a function') &&
                ex.message !== `Cannot find module './${Type}/${Type}.types'`
              ) {
                console.log('catch types', ex.message);
              }
            });
        }

        await import(`@/modules/${Type}/${Type}.store`)
          .then(async ({ default: registerModule }) => {
            result.VuexModulePath = await registerModule(getStore(), Data, m.VuexModulePath);
          })
          .catch((ex) => {
            if (ex.message !== `Cannot find module './${Type}/${Type}.store'`)
              console.log('catch store', ex.message);
          });

        // console.log('BEFORE', SET_MODULE_LOADED, result.Module.Id);
        commit(SET_MODULE_LOADED, result);

        // console.log('loaded', Type);
        resolve();
      });
    };

    // console.log(gettersT.GET_MODULES);
    for (let i = 0; i < state.PageData.Slots.length; i++) {
      const slot = state.PageData.Slots[i];
      //console.log('load slot', slot.Name);
      await Promise.all(slot.Modules.filter((m) => m.Type).map(loadModule));
      //console.log('loaded slot', slot.Name);
    }

    // commit(SET_ALL_MODULE_LOADED);

    if (!isSSR()) {
      commit(REGISTER_MODULES);
      dispatch(INIT_WINDOW_WATCHERS);
      dispatch(INIT_GROUP_URI);
    }
    //console.log('finish init modules');
  },
};

export const REGISTER_MODULES = 'REGISTER_MODULES';
export const SET_MODULE_LOADED = 'SET_MODULE_LOADED';
export const SET_MODULE_VISIBLE = 'SET_MODULE_VISIBLE';
export const SET_MODULE_MOUNTED = 'SET_MODULE_MOUNTED';
export const SET_ALL_MODULE_LOADED = 'SET_ALL_MODULE_LOADED';
const mutations: MutationTree<ModulesStatus> = {
  [REGISTER_MODULES](state) {
    // Vue.set(state, 'PageData', pageData);
    // state.PageData = { ...state.PageData, ...pageData };
    // state.PageData = pageData;

    state.RegisteredModules = [];

    state.PageData.Slots.forEach((s) => {
      s.Modules.forEach((m) => {
        if (m.Type && !state.RegisteredModules.includes(m.Type)) {
          state.RegisteredModules.push(m.Type);
          Vue.component(m.Type, () => import(`@/modules/${m.Type}/${m.Type}.vue`));

          // import(`./../../modules/${m.Type}/${m.Type}.vue`)
          //   .then(() => {
          //     console.log('successed registered ', m.Type);
          //   })
          //   .catch((ex) => {
          //     console.log('failed registered ', m.Type, ex);
          //   });
        }
      });
    });
  },
  [SET_MODULE_LOADED](state, module: ModuleLoadingParams) {
    const { Module, VuexModulePath, Data } = module;
    // // console.log(SET_MODULE_LOADED, Module.Id);
    if (VuexModulePath) Module.VuexModulePath = VuexModulePath;
    if (Data) Module.Data = Data;

    // Module.IsPreLoaded = true;
  },
  [SET_MODULE_VISIBLE](state, module: ModuleVisiblityParams) {
    // console.log(SET_MODULE_VISIBLE, module);

    Vue.set(module.Module, 'IsVisible', module.IsVisible);

    // if (firstVisible !== state.FirstVisibleModule) state.FirstVisibleModule = firstVisible;
    // console.log(SET_MODULE_VISIBLE, module.Module.Id);
  },
  [SET_MODULE_MOUNTED](state, module: PageModule) {
    module.IsMounted = true;
  },
  [SET_ALL_MODULE_LOADED](state) {
    // state.IsAllModulesLoaded = true;
    // console.log(SET_ALL_MODULE_LOADED);
    // setTimeout(() => document.querySelector('#ssr-stub')?.remove(), 100);
  },
  [CLEAR_MODULE_GROUP_URI](state, moduleGroupUri) {
    state.PageData.Slots.forEach(
      (slot) =>
        (slot.Modules = slot.Modules.filter((m) => {
          return m.GroupUri?.toLowerCase() !== moduleGroupUri.toLowerCase();
        })),
    );
  },
};

export const GET_PAGE_DATA = 'GET_PAGE_DATA';
export const GET_MODULES = 'GET_MODULES';
export const GET_GROUP_URI_MODULE = 'GET_GROUP_URI_MODULE';
export const GET_FIRST_VISIBLE_MODULE = 'GET_FIRST_VISIBLE_MODULE';
export const GET_GROUP_URI_MOUNTED = 'GET_GROUP_URI_MOUNTED';
export const GET_LOADED_MODULES_FOR_SLOT = 'GET_MODULES_FOR_SLOT';
export const GET_HERO = 'GET_HERO';
export const GET_TOP_RIBBON = 'GET_TOP_RIBBON';
export interface ModulesLoadGetters {
  GET_PAGE_DATA: PageData;
  GET_MODULES: PageModule[];
  GET_GROUP_URI_MODULE: (groupUri: string) => PageModule | undefined;
  GET_FIRST_VISIBLE_MODULE: PageModule;
  GET_GROUP_URI_MOUNTED: (groupUri: string) => PageModule;
  GET_LOADED_MODULES_FOR_SLOT: (slotName) => PageModule[];
}

const getters: GetterTree<ModulesStatus, RootState> = {
  [GET_PAGE_DATA](state) {
    return state.PageData;
  },
  [GET_MODULES](state) {
    return (
      state.PageData?.Slots?.reduce(
        (modules: PageModule[], slot) => [...modules, ...slot.Modules],
        [],
      ) || []
    );
  },
  [GET_GROUP_URI_MODULE](state, getters: ModulesLoadGetters) {
    return (groupUri: string) =>
      getters.GET_MODULES.find((m) => groupUri && m.GroupUri === groupUri.replace('#', ''));
  },
  [GET_HERO](state, getters: ModulesLoadGetters) {
    return () => getters.GET_MODULES.find(m => m.Type === 'HeroModule' || m.Type === 'HeroShortModule' || m.Type === 'HeroCarouselDerModule');
  },
  [GET_TOP_RIBBON](state, getters: ModulesLoadGetters) {
    return () => getters.GET_MODULES.find(m => m.Type === 'NavigationModule')?.Data?.AnnouncementData
  },
  [GET_FIRST_VISIBLE_MODULE](
    state,
    getters: ModulesLoadGetters,
    rootState: RootState,
    rootGetters: RootGetters,
  ) {
    // console.log(GET_FIRST_VISIBLE_MODULE, 'called');

    const abOffset = rootGetters[anchorBarOffset];
    const position = rootGetters[windowOffset];

    const filterModules = ['AnchorBarModule', 'BackToTopModule', 'NavigationModule'];
    const modules = getters.GET_MODULES.filter((m) => !filterModules.includes(m.Type));

    // console.log('position', position, rootGetters);
    let firstVisible = null;
    for (let i = 0; i < modules.length; i++) {
      const m = modules[i];
      // console.log('module', m);

      const divOffset = modulePageOffset(m.UUID);
      if (divOffset) {
        // console.log(
        //   'check',
        //   divOffset.top,
        //   position,
        //   abOffset,
        //   divOffset.top - position,
        //   m.Type,
        //   m.Id,
        // );

        if (divOffset.top - position + abOffset > 0) {
          // console.log(
          //   'break',
          //   divOffset.top,
          //   position,
          //   abOffset,
          //   divOffset.top - position,
          //   m.Type,
          //   m.Id,
          // );
          break;
        }
      }

      firstVisible = m;
    }

    // if (firstVisible) {
    //   console.log('first visible', firstVisible.Id, firstVisible.Type);
    // }
    return firstVisible;
  },
  [GET_GROUP_URI_MOUNTED](state, getters: ModulesLoadGetters) {
    return (groupUri: string) => {
      // if (
      //   !state.PageData?.Slots?.find((s) => s.Name === 'Main')?.Modules.every((m) => m.IsMounted)
      // ) {
      //   return null;
      // }

      // const modules = getters.GET_MODULES;
      // const firstInGroup = modules.find((m) => m.GroupUri == groupUri);

      // console.log(
      //   'firstInGroup',
      //   groupUri,
      //   firstInGroup?.Id,
      //   firstInGroup?.IsPreLoaded,
      //   firstInGroup?.IsMounted,
      // );
      // if (firstInGroup && firstInGroup.IsMounted) {
      //   return firstInGroup;
      // }

      return null;
    };
  },
  [GET_LOADED_MODULES_FOR_SLOT](state) {
    return (slotName: string) => {
      let result = state.PageData.Slots.find((s) => s.Name === slotName)?.Modules || [];

      // if (!state.IsAllModulesLoaded) result = result.filter((m) => m.IsPreLoaded);

      // console.log(result.map((m) => m.Id));
      return result;
    };
  },
};

export default {
  namespaced: false,
  // state: {
  //   FirstVisibleModule: null,
  //   // RegisteredModules: [],
  //   // IsAllModulesLoaded: false,
  // },
  actions,
  mutations,
  getters,
} as Module<ModulesStatus, RootState>;
