import Vue from 'vue';

import './SSRPlugins';

import VModal from 'vue-js-modal';
Vue.use(VModal);

import VueWaypoint from 'vue-waypoint';
Vue.use(VueWaypoint);

import { Affix } from 'vue-affix';
Vue.use(Affix);

import lineClamp from 'vue-line-clamp';
Vue.use(lineClamp);
