import { Component, Vue } from 'vue-property-decorator';

import ResponsiveImageRatio from '@/components/ResponsiveImageRatio/ResponsiveImageRatio.vue';
import { ImageWidths } from '@/common/interfaces/SizeBreakpoints';

const TemplateLoadingProps = Vue.extend({
  props: {
    loaded: Boolean,
  },
  components: {
    ResponsiveImageRatio,
  },
});

@Component({})
export default class TemplateLoading extends TemplateLoadingProps {
  get imageUrl() {
    return '/Images/favicon96LEX.png';
  }

  get imageAlt() {
    return 'Lindblad Loader';
  }

  imageWidths: ImageWidths = {
    def: 993,
    sizes: {
      sm: 993,
      md: 992,
      lg: 993,
      xl: 993,
    },
  };
}
