import axios from 'axios'
import { ADVERTISING_CATEGORIES, FUNCTIONAL_CATEGORIES } from '../categories'

interface Destination {
  id: string
  name: string
  creationName: string
  description: string
  website: string
  category: string
}

export interface normalizedDestinations {
  categoryPreferences: CategoryPreferences
  marketingCategories: string[]
  advertisingCategories: string[]
  functionalCategories: string[]
}

export interface managerContent {
  additionalIntegrationsCategoryDescription: string
  bannerContent: string
  bannerSubContent: string
  cancelDialogContent: string
  cancelDialogTitle: string
  functionalCategoryDescription: string
  marketingAndAnalyticsCategoryDescription: string
  preferencesDialogContent: string
  preferencesDialogTitle: string
  targetingAndAdvertisingCategoryDescription: string,
  enableConsentManager: boolean,
  okCta: string,
}

export interface CategoryPreferences {
  [category: string]: boolean | null | undefined
}

export interface Preferences {
  customPreferences?: CategoryPreferences
  destinationPreferences?: CategoryPreferences
  cookieName?: string
}

function normalizeDestinations(destinations: Destination[]) {
    const categoryPreferences: CategoryPreferences = {}
    const marketingCategories: string[] = []
    const advertisingCategories: string[] = []
    const functionalCategories: string[] = []
  
    for (const destination of destinations) {
      categoryPreferences[destination.creationName] = true
      if (ADVERTISING_CATEGORIES.find(c => c === destination.category)) {
        advertisingCategories.push(destination.creationName)
      } else if (FUNCTIONAL_CATEGORIES.find(c => c === destination.category)) {
        functionalCategories.push(destination.creationName)
      } else {
        // Fallback to marketing
        marketingCategories.push(destination.creationName)
      }
    }

    return { marketingCategories, advertisingCategories, functionalCategories, categoryPreferences }
}

export function loadNonSegmentScripts() {
  const additionalScriptsPlain = document.querySelectorAll('script[class^="optanon-category"][type="text/plain"]') as any;
  if (additionalScriptsPlain.length) {
    additionalScriptsPlain.forEach(el => {
      let script = document.createElement('script');
      script.type = 'text/javascript';
      script.className = el.className;
      if (el.src) {
        script.src = el.src
      } else if (el.innerHTML) {
        script.innerHTML = el.innerHTML
      }
      document.head.appendChild(script);
      el.remove();
    })
  }
}

export async function fetchSegmentData(writeKey: string, isUSA: boolean) {
  const content = await fetchContent(isUSA)
  const categories = await fetchCategories(writeKey)
  return {
    categories,
    content,
  }
}

export async function fetchCategories(writeKey: string) {
  const integrationsURL = `https://cdn.segment.com/v1/projects/${writeKey}/integrations`
  const res = await axios.get(integrationsURL)
  return normalizeDestinations(res.data)
}

export async function fetchContent(isUSA: boolean) {
  const localDevDomain = process.env.NODE_ENV === 'development' ? 'https://world.stg2019.expeditions.com' : ''
  const locale = isUSA ? 'en-US' : 'en'
  const contentURL = `${localDevDomain}/api/v1.0/${locale}/content/consentManagerModel`
  const cachedContent = sessionStorage.getItem('concentManagerContent')
  const cachedlLocale = sessionStorage.getItem('locale')
  if (cachedContent && locale === cachedlLocale) {
    return JSON.parse(cachedContent)
  }
  const content = await axios.get(contentURL);
  sessionStorage.setItem('concentManagerContent', content.data)
  sessionStorage.setItem('locale', locale)
  return JSON.parse(content.data)
}

export function parseHTML(html: string, rootTag: string, innerTag: string, createElement: any): string {
  const htmlWithoutNbsp = html.replace(/&#160;/g, '');
  const rootBlock = document.createElement(rootTag);
  rootBlock.innerHTML = htmlWithoutNbsp;
  const innerBlocks = rootBlock.querySelectorAll('p')

  let reactElements = []
  innerBlocks.forEach(innerBlock => {
    const innerBlockPart = innerBlock.innerHTML.split('<a href="')
    let childrenReact = []
    innerBlockPart.forEach(el => {
      if (!el.includes('http')) {
        childrenReact.push(createElement('span', null, el))
      } else {
        const href = el.substring(0, el.indexOf('"'))
        const text = el.substring(el.indexOf('>') + 1, el.indexOf('</a>'))
        const afterLink = el.substring(el.indexOf('</a>') + 4)
        childrenReact.push(createElement('a', { href, target: '_blank' }, text))
        childrenReact.push(createElement('span', null, afterLink.length !== 1 ? ' ': '', afterLink.trim(), ' '))
      }
    })
    reactElements.push(createElement(innerTag, null, childrenReact))
  })
  return createElement(rootTag, null, reactElements)
}